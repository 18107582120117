.links {
  text-align: center;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

.links > h2 {
  font-weight: 700;
  font-size: 1.563rem;
  line-height: 1.875rem;
  margin-bottom: 0.375rem;
}

.title {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 0.938rem;
  text-align: center;
  color: #f3bf99;
  margin: 0;
}

.website {
  font-weight: 400;
  font-size: 0.64rem;
  line-height: 150%;
  margin-top: 0.5rem;
  margin-bottom: 0.9rem;
}

.buttons {
  margin-top: 0;
  display: flex;
  justify-content: space-evenly;
}

.contact-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.563rem 0.813rem 0.563rem 0.688rem;
  gap: 0.5rem;
  width: 7.188rem;
  height: 2.125rem;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  border-radius: 0.375rem;
  box-shadow: 0 0.063rem 0.125rem rgba(0, 0, 0, 0.05);
}

.contact-button:hover {
  transform: scale(1.05);
}

.linkedin-button {
  background: #5093e2;
  border: none;
  margin-right: 1rem;
  color: #ffffff;
}

.email-button {
  background: #ffffff;
  border: 0.063rem solid #d1d5db;
  margin-left: 1rem;
  color: #374151;
}
